import React from 'react'
import { useRouter } from 'next/router'
import { NotFound } from '~elements'

const Error = () => {
  const router = useRouter()

  return <NotFound router={router} />
}

Error.propTypes = {}

export default Error
